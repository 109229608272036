<template>
	<!-- 问题意见清单 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="标的物">
						<el-select class="selItemInput" v-model="subjectNo" placeholder="请选择">
							<el-option
							  v-for="item in subjectList"
							  :key="item.displayValue"
							  :label="item.displayName"
							  :value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="提出时间">
						<el-date-picker
						  class="selItemInput"
						  v-model="poseTime"
						  type="daterange"
						  range-separator="至"
						  value-format="yyyy-MM-dd"
						  start-placeholder="开始日期"
						  end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="4" class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="resetFormData()">重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="selGetDataList()">检索</el-button>
				</el-col>
			</el-row>	  
		</el-form>
	  </el-card>
	  <!-- <el-row style="margin-top: 20px;">
	  	<el-col :offset="12" :span="12" style="text-align: right;">
	  		<el-pagination
	  		  background
	  		  layout="prev, pager, next"
	  		  :pager-count="5"
	  		  :current-page="page"
	  		  :page-size="limit"
	  		  :total="totalCount">
	  		</el-pagination>
	  	</el-col>
	  </el-row> -->
	  <div :style="siteContentViewHeight()">
		  <el-card 
			style="margin-top: 20px; height: 100%;" 
			body-style="height: 100%; padding: 0;">
			  <el-table
				class="dataListTable"
				:data="dataList"
				header-align="center"
				height="100%"
				default-expand-all
				style="width: 100%;"
				row-key="id"
				row-class-name="row-expand-cover" 
				header-cell-class-name="dataListTHeader"
				:tree-props="{children: 'subjectNoList', hasChildren: 'hasChildren'}">
				<el-table-column
					width="80"
					prop="index"
					align="center">
				</el-table-column>
				<el-table-column
				  prop="subjectNo"
				  label="标的物"
				  align="center">
				</el-table-column>
				<el-table-column
				  prop="majorName"
				  label="专业"
				  align="center">
				</el-table-column>
				<el-table-column
				  prop="stateFlgCount"
				  label="整改问题"
				  align="center">
				</el-table-column>
				<el-table-column
				  prop="stateFlgOver"
				  label="已整改 "
				  align="center">
				</el-table-column>
				<el-table-column
				  prop="stateFlgWait"
				  label="截止目前未整改 "
				  align="center">
				</el-table-column>
			  </el-table>
		  </el-card>
	  </div>
  </div>
</template>
<script>
	import $common from "@/utils/common.js"
export default {
  data() {
    return {
      tabIndex: "0",
	  form:{},
	  subjectList:[],
	  subjectNo:"",
	  poseTime:[],
	  dataList:[],
	  tableSelVal:[]
    };
  },
  components: {
	  
  },
  mounted() {
	this.getDataList();
    this.getSubjectDropDown();
  },
  methods: {
	siteContentViewHeight () {
	  var height = this.$store.state.common.documentClientHeight - 200;
	  return { height: height + 'px' }
	},
	handleSelectionChange(val) {
		this.tableSelVal = val;
	},
	tableRowClassName({ row, rowIndex }) {
	  let color = ''
	  for(let item of this.tableSelVal){
		if(item.id === row.id)color = 'table-SelectedRow-bgcolor'
	  }
	  return color
	},
	selGetDataList(){
		this.page = 1;
		this.getDataList();
	},
	getDataList() {
	  this.$http({
	    url:  this.$store.state.httpUrl + "/business/probleminfo/listSubjectNo",
	    method: "get",
	    params: {
			workNo: $common.getItem("workNo"),
			subjectNo: this.subjectNo,
			poseTimeBegin: this.poseTime[0]?this.poseTime[0]:"",
			poseTimeEnd: this.poseTime[1]?this.poseTime[1]:"",
			limit: this.limit,
			page: this.page
		},
	  }).then(({ data }) => {
	    if (data && data.resultCode === 200) {
			this.dataList = data.body;
	    }
	  });
	},
	getSubjectDropDown(){
		this.$http({
		  url:  this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		  method: "get",
		  params: {},
		}).then(({ data }) => {
		  if (data && data.resultCode === 200) {
			this.subjectList = data.body;
		  }
		});
	},
	resetFormData(){
		this.subjectNo = "";
		this.poseTime = [];
	}
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
